import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'

import { Link } from 'gatsby'

import { media } from '../themes'
// import { Default, Desktop, Tablet, Phone } from './Responsive'

import Layout from '../components/layout'
import { Title, Main, Content } from '../components/styles/pages'

import { NextLink, PrevLink } from '../components/NavigationArrows'

import BlockContent from '@sanity/block-content-to-react'
import withSanityImage from '../components/hoc/withSanityImage'

const ArticleTitle = styled.h1`
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 0.5rem;
`

const Article = styled.article`
  margin-bottom: 5rem;
`

const ArticleContent = styled.div`
  width: 50vw;
  margin: 0 auto;

  ${media.tablet`
    width: 100%;
  `}

  iframe {
    width: 100%;
  }
`

const NewsContent = styled(Content)`
  ${media.tablet`
    width: 100%;
    
    ${NextLink}, ${PrevLink} {
      text-indent: none;
      position: static;
      display: inline-block;
      top: auto;
      right: auto;
      left: auto;
      width: 50%; 
      margin: 0 0 2rem;
      text-indent: initial;
      
      height: auto;
      line-height: 28px;
    }

    ${NextLink} {
      background-position: right center;
      padding-right: 30px;
      text-align: right;
    }

    ${PrevLink} {
        background-position: left center;
        padding-left: 30px;
    }

  `}
`

const VideoContainer = styled.div`
  position: relative;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledImage = styled(Img)`
  margin-bottom: 2rem;
`

const serializers = {
  types: {
    htmlSnippet: ({ node: { code } }) => {
      return <div dangerouslySetInnerHTML={{ __html: code }} />
    },

    youTube: ({ node: { youTube } }) => {
      const code = (youTube && youTube.split('=')[1]) || ''

      if (!code) return null

      return (
        <VideoContainer>
          <iframe
            title={`https://www.youtube.com/embed/${code}`}
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${code}`}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </VideoContainer>
      )
    },

    image: ({ node: { asset } }) => {
      if (!asset) return null
      const SanityImage = withSanityImage(asset._ref)(StyledImage)

      return <SanityImage placeholderStyle={{ filter: 'blur(10px)' }} />
    },
  },
}

const PostPageTemplate = ({ data, pageContext, windowWidth, windowHeight }) => {
  const { totalPage, currentPage } = pageContext

  const posts = get(data, 'allSanityPost.edges', [])

  const previousPage =
    currentPage + 1 >= totalPage ? null : `/news/page/${currentPage + 1}`
  const nextPage =
    currentPage - 1 < 0
      ? null
      : currentPage - 1 === 0
      ? `/news/`
      : `/news/page/${currentPage - 1}`

  return (
    <Layout>
      <Main>
        <Title>News</Title>
        <NewsContent>
          {/* {totalPage} */}
          {posts.map(
            ({ node: { title, _rawBody, mainImage, gallery } = {} }, index) => (
              <Article key={index}>
                <ArticleTitle>{title}</ArticleTitle>
                <ArticleContent>
                  {mainImage && (
                    <StyledImage
                      fluid={get(mainImage, 'asset.fluid')}
                      placeholderStyle={{ filter: 'blur(10px)' }}
                      // style={{
                      //   margin: '0 auto',
                      //   width: w,
                      //   height: h,
                      //   boxShadow:
                      //     this.props.no_frame_shadow === true
                      //       ? null
                      //       : '0px 1px 6px #616161',
                      // }}
                    />
                  )}

                  {gallery &&
                    gallery.map(mainImage => (
                      <StyledImage
                        key={mainImage.id}
                        fluid={get(mainImage, 'asset.fluid')}
                        placeholderStyle={{ filter: 'blur(10px)' }}
                      />
                    ))}

                  {_rawBody && (
                    <BlockContent
                      blocks={_rawBody}
                      serializers={serializers}
                      // imageOptions={{ w: 320, h: 240, fit: 'max' }}
                      // projectId="fa60ke5o"
                      // dataset="production"
                    />
                  )}
                </ArticleContent>
              </Article>
            )
          )}
          {previousPage && (
            <PrevLink>
              <Link to={previousPage}>Previous</Link>
            </PrevLink>
          )}
          {nextPage && (
            <NextLink>
              <Link to={nextPage}>Next</Link>
            </NextLink>
          )}
        </NewsContent>
      </Main>
    </Layout>
  )
}

export default PostPageTemplate

export const query = graphql`
  query($index: Int!, $perPage: Int!) {
    allSanityPost(
      sort: { fields: _createdAt, order: DESC }
      limit: $perPage
      skip: $index
    ) {
      edges {
        node {
          id
          title
          publishedAt
          _rawBody
          body {
            _key
            _type
            style
            children {
              _key
              _type
              text
              marks
            }
          }
          gallery {
            _key
            _type
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          mainImage {
            _type
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            _type
            current
          }
        }
      }
      totalCount
    }
  }
`
